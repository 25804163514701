<template>
  <v-container id="dashboard" fluid tag="section">
    <v-row v-if="records.length > 0" dense>
      <v-col class="d-flex" cols="12">
        <v-icon>mdi-book</v-icon>
        <h1 class="ml-1">{{ $t("Monografii") }}</h1>
      </v-col>
      <v-col cols="12" class="pt-0">
        <v-divider />
      </v-col>
    </v-row>
    <v-row v-if="records.length > 0">
      <v-col
        v-for="itm in records"
        :key="itm._id"
        cols="12"
        :sm="selectedView == 0 ? 6 : 12"
        :md="selectedView == 0 ? 6 : 12"
        :lg="selectedView == 0 ? 4 : 12"
        :xl="selectedView == 0 ? 3 : 12"
      >
        <view-mon :record="itm" :baseURL="baseURL" />
      </v-col>
      <v-col cols="12" class="d-flex justify-center">
        <router-link to="/mon" class="v-breadcrumbs__item">
          <v-btn elevation="1" small>
            {{ $t("Mai multe...") }}
          </v-btn>
        </router-link>
      </v-col>
    </v-row>
    <v-row v-if="recordsath.length > 0" dense>
      <v-col class="d-flex" cols="12">
        <v-icon>mdi-book</v-icon>
        <h1 class="ml-1">{{ $t("Autorități") }}</h1>
        <v-spacer />
      </v-col>
      <v-col cols="12" class="pt-0">
        <v-divider />
      </v-col>
    </v-row>
    <v-row v-if="recordsath.length > 0">
      <v-col
        v-for="itm in recordsath"
        :key="itm._id"
        cols="12"
        :sm="selectedView == 0 ? 6 : 12"
        :md="selectedView == 0 ? 6 : 12"
        :lg="selectedView == 0 ? 4 : 12"
        :xl="selectedView == 0 ? 3 : 12"
      >
        <view-ath :record="itm" :baseURL="baseURL" />
      </v-col>
      <v-col cols="12" class="d-flex justify-center">
        <router-link to="/ath" class="v-breadcrumbs__item">
          <v-btn elevation="1" small>
            {{ $t("Mai multe...") }}
          </v-btn>
        </router-link>
      </v-col>
    </v-row>
    <v-row v-if="recordsart.length > 0" dense>
      <v-col class="d-flex" cols="12">
        <v-icon>mdi-book-open-page-variant</v-icon>
        <h1 class="ml-1">{{ $t("Articole") }}</h1>
        <v-spacer />
      </v-col>
      <v-col cols="12" class="pt-0">
        <v-divider />
      </v-col>
    </v-row>
    <v-row v-if="recordsart.length > 0">
      <v-col
        v-for="itm in recordsart"
        :key="itm._id"
        cols="12"
        :sm="selectedView == 0 ? 6 : 12"
        :md="selectedView == 0 ? 6 : 12"
        :lg="selectedView == 0 ? 4 : 12"
        :xl="selectedView == 0 ? 3 : 12"
      >
        <view-art :record="itm" :baseURL="baseURL" />
      </v-col>
      <v-col cols="12" class="d-flex justify-center">
        <router-link to="/art" class="v-breadcrumbs__item">
          <v-btn elevation="1" small>
            {{ $t("Mai multe...") }}
          </v-btn>
        </router-link>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import axios from "../../../plugins/axios"
import ViewMon from "../components/containers/ViewMon"
import ViewAth from "../components/containers/ViewAth"
import ViewArt from "../components/containers/ViewArt"
export default {
  name: "DashboardDashboard",
  components: { ViewMon, ViewAth, ViewArt },
  data() {
    return {
      selectedView: 0,
      records: [],
      recordsath: [],
      recordsart: []
    }
  },
  computed: {
    companyId() {
      return this.$store.getters.companyId
    },
    baseURL() {
      return axios.defaults.baseURL
    }
  },
  created() {
    this.getRecords()
  },
  methods: {
    openDetails(itm) {
      this.$log("openDetails ", itm)
      this.$router.push("/mon/" + itm._id)
    },
    getRecords() {
      axios.get("ebib_marc_mon?max_results=12&page=1").then(response => {
        this.records = []
        response.data._items.map(itm => {
          const rec = {}
          rec._id = itm._id
          for (const itms in itm) {
            if (itms != "fields") {
              rec[itms] = itm[itms]
            }
          }
          if (itm.fields["200"]) {
            rec.title = itm.fields["200"][0].a[0].val
            if (itm.fields["200"][0].c) {
              rec.title += ". " + itm.fields["200"][0].c[0].val
            }
            if (itm.fields["200"][0].d) {
              rec.title += " = " + itm.fields["200"][0].d[0].val
            }
            if (itm.fields["200"][0].h) {
              rec.title += " " + itm.fields["200"][0].h[0].val
            }
            if (itm.fields["200"][0].i) {
              rec.title += " : " + itm.fields["200"][0].i[0].val
            }
          } else {
            rec.title = ""
          }
          for (const flds in itm.fields) {
            for (const fld in itm.fields[flds]) {
              for (const sub in itm.fields[flds][fld]) {
                if (sub.length == 1) {
                  for (const subs in itm.fields[flds][fld][sub]) {
                    if (rec["fields." + flds + "." + sub + ".val"]) {
                      rec["fields." + flds + "." + sub + ".val"] += ", " + itm.fields[flds][fld][sub][subs].val
                    } else {
                      rec["fields." + flds + "." + sub + ".val"] = itm.fields[flds][fld][sub][subs].val
                    }
                  }
                } else {
                  rec["fields." + flds + "." + sub] = itm.fields[flds][fld][sub]
                }
              }
            }
          }
          this.records.push(rec)
        })
      })
      axios.get("ebib_marc_ath?max_results=12&page=1").then(response => {
        this.recordsath = []
        response.data._items.map(itm => {
          const rec = {}
          rec._id = itm._id
          for (const itms in itm) {
            if (itms != "fields") {
              rec[itms] = itm[itms]
            }
          }
          if (itm.ctg == "nume-personal") {
            rec.title = itm.fields["200"][0].a[0].val
            if (itm.fields["200"][0].b) {
              rec.title += ", " + itm.fields["200"][0].b[0].val
            }
            if (itm.fields["920"]) {
              rec.title += " (" + itm.fields["920"][0].a[0].val + ")"
            }
            if (itm.fields["200"][0].f) {
              rec.title += " (" + itm.fields["200"][0].f[0].val + ")"
            }
          } else {
            const flds = [200, 210, 215, 250, 270, 230, 220, 216, 260, 240, 245, 280, 235, 243, 217]
            flds.map(field => {
              if (itm.fields[field]) {
                rec.title = itm.fields[field][0].a[0].val
              }
            })
          }
          for (const flds in itm.fields) {
            for (const fld in itm.fields[flds]) {
              for (const sub in itm.fields[flds][fld]) {
                if (sub.length == 1) {
                  for (const subs in itm.fields[flds][fld][sub]) {
                    if (rec["fields." + flds + "." + sub + ".val"]) {
                      rec["fields." + flds + "." + sub + ".val"] += ", " + itm.fields[flds][fld][sub][subs].val
                    } else {
                      rec["fields." + flds + "." + sub + ".val"] = itm.fields[flds][fld][sub][subs].val
                    }
                  }
                } else {
                  rec["fields." + flds + "." + sub] = itm.fields[flds][fld][sub]
                }
              }
            }
          }
          this.recordsath.push(rec)
        })
      })
      axios.get("ebib_marc_art?max_results=12&page=1").then(response => {
        this.recordsart = []
        response.data._items.map(itm => {
          const rec = {}
          rec._id = itm._id
          for (const itms in itm) {
            if (itms != "fields") {
              rec[itms] = itm[itms]
            }
          }
          if (itm.fields["200"]) {
            rec.title = itm.fields["200"][0].a[0].val
          } else {
            rec.title = ""
          }
          for (const flds in itm.fields) {
            for (const fld in itm.fields[flds]) {
              for (const sub in itm.fields[flds][fld]) {
                if (sub.length == 1) {
                  for (const subs in itm.fields[flds][fld][sub]) {
                    if (rec["fields." + flds + "." + sub + ".val"]) {
                      rec["fields." + flds + "." + sub + ".val"] += ", " + itm.fields[flds][fld][sub][subs].val
                    } else {
                      rec["fields." + flds + "." + sub + ".val"] = itm.fields[flds][fld][sub][subs].val
                    }
                  }
                } else {
                  rec["fields." + flds + "." + sub] = itm.fields[flds][fld][sub]
                }
              }
            }
          }
          this.recordsart.push(rec)
        })
      })
    }
  }
}
</script>
<style></style>
